import React from "react"
import styled from "styled-components"
import Downtown from "../../images/downtown2.webp"
import Layout from "../../components/layout-canada"
import SEO from "../../components/seo"
import LeafBorder from "../../images/hand-painted-leaf-border-cut.png"

const Picture = styled.div`
  background-image: url("${Downtown}");
  background-size: cover;
  width: 100%;
  height: 400px;
  background-position: 10%;
  margin-bottom: 42px;
  @media (max-width: 768px) {
    background-position: 45%;
  }
`

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 260px;
  margin-bottom: 32px;
  background-size: 300px 30px;
`

const H1 = styled.h1`
  margin-bottom: 6px;
`

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

const H4 = styled.h4`
  font-family: "Benton Sans";
  margin-bottom: 24px;
`

const H5 = styled.h5`
  font-family: "Benton Sans";
  margin-bottom: 20px;
`

const Ul = styled.ul`
  font-family: "Benton Sans";
`

class TransportPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Other things to do | Sara & Tim" />
        <H1>Other things to do</H1>
        <LeafBorderPicture />
        <Picture></Picture>
        <P>
          For those of you staying in Kelowna a few days, here are a list of
          other attractions and activities we enjoy in and around the city:
        </P>
        <Ul>
          <li>
            <a href="https://www.tripadvisor.co.uk/Attraction_Review-g154933-d3224935-Reviews-Kangaroo_Creek_Farm-Kelowna_Okanagan_Valley_British_Columbia.html">
              Kangaroo Creek Farm
            </a>{" "}
            - especially if you have kids (or are a big kid)
          </li>
          <li>
            <a href="https://www.kettlevalleyrail.org/">
              Kettle Valley Steam Railway
            </a>{" "}
            - only open Saturday - Monday in June
          </li>
          <li>Enjoying a drink at one of the many craft breweries in town</li>
          <li>Walking along the lakefront</li>
          <li>
            Sampling the hot drinks and baked goods at one of the many great
            cafes, such as{" "}
            <a href="https://pulpfictioncoffeehouse.com/">Pulp Fiction</a> and{" "}
            <a href="https://www.thebreadcompany.ca/">Bread Co.</a>
          </li>
          <li>
            Checking out Kelowna's independent shops, such as{" "}
            <a href="https://mosaicbooks.ca/">Mosaic book shop</a>
          </li>
          <li>
            Stocking up on vitamins in the most delicious way possible at one of
            Kelowna's many fresh fruit stands (juicy cherries a must!)
          </li>
          <li>
            Exploriong the local vineyards, of course! Our favourites are{" "}
            <a href="https://www.kitschwines.ca/">Kitsch</a> (also offer picnics
            if you want to make an afternoon of it),{" "}
            <a href="https://www.missionhillwinery.com/">Mission Hill</a> and{" "}
            <a href="https://www.rollingdale.ca/">Rollingdale Winery</a> (for
            organic wines!)
          </li>
          <li>
            If you're a cider fan,{" "}
            <a href="http://somacidery.com/">Soma Cidery</a> is definitely worth
            a visit (worth it just for the Lavendar & Honey cider alone!)
          </li>
          <li>
            For those with a sweet tooth,{" "}
            <a href="https://www.jammery.com/">The Jammery</a> is a perfect stop
            on the way to or from the airport, with shelves of delicious (and
            sometimes whacky) preserved fruit combinations and the restaurant's
            all-day breakfasts will do you for the day!
          </li>
          <li>
            With such a huge lake, watersports are obviously a big deal in
            Kelowna. There are many options to get your feet wet, including
            kayaking, jet skiing, paragliding and wakeboarding.
          </li>
        </Ul>
      </Layout>
    )
  }
}

export default TransportPage
